import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { LocalStorageService } from './localStorage.service';
import { Router } from '@angular/router';
import { ChangePwdModal } from '../../shared/modals/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User | null>(
      this.retrieveStoredUser()
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private retrieveStoredUser(): User | null {
    const storedUser = this.localStorageService.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  login(
    email: string,
    password: string,
    deviceId: string,
    loginThroughOtp: boolean
  ): Observable<any> {
    const requestPayload = loginThroughOtp
      ? {
          userInput: email,
          otp: password,
          deviceType: 'web',
          deviceId,
        }
      : {
          userInput: email,
          password,
          deviceType: 'web',
          deviceId,
        };

    return this.http.post<any>(AppConfigData.loginUrl, requestPayload).pipe(
      map((response) => {
        if (response && response.data) {
          this.storeUserInLocalStorage(response.data);
          this.currentUserSubject.next(response.data);
        }
        return response;
      }),
      catchError((error) => {
        console.error('Login failed:', error);
        return throwError(error);
      })
    );
  }

  apiError(): Observable<any> {
    this.currentUserSubject.next(null);
    this.localStorageService.clear();
    this.router.navigate(['/auth/login']);
    return of(null);
  }

  logout(): Observable<any> {
    return this.http.post<any>(AppConfigData.logOutUrl, {}).pipe(
      map((response) => {
        if (response) {
          this.currentUserSubject.next(null);
          this.localStorageService.clear();
          this.router.navigate(['/auth/login']);
          // this.router.navigate(['/masters']);
        }
        return response;
      }),
      catchError((error) => {
        console.error('Logout failed:', error);
        return throwError(error);
      })
    );
  }

  autoRefreshToken(requestPayload: any): Observable<any> {
    return this.http.post<any>(AppConfigData.refreshToken, requestPayload).pipe(
      map((response) => {
        if (response && response.data) {
          this.storeUserInLocalStorage(response.data);
          this.currentUserSubject.next(response.data);
        }
        return response;
      }),
      catchError((error) => {
        console.error('Token refresh failed:', error);
        return throwError(error);
      })
    );
  }

  sendOtp(email: string): Observable<any> {
    const url = `${AppConfigData.sendOtpUrl}?email=${email}`;
    return this.http.post<any>(url, {}).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Send OTP failed:', error);
        return throwError(error);
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    const url = `${AppConfigData.forgotPwdUrl}?email=${email}`;
    return this.http.post<any>(url, {}).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Forgot password failed:', error);
        return throwError(error);
      })
    );
  }

  resetPassword(requestPayload: any): Observable<any> {
    return this.http.post<any>(AppConfigData.resetPwdUrl, requestPayload).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Reset password failed:', error);
        return throwError(error);
      })
    );
  }

  updatePassword(requestPayload: ChangePwdModal): Observable<any> {
    return this.http.post<any>(AppConfigData.updatePwdUrl, requestPayload).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Update password failed:', error);
        return throwError(error);
      })
    );
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(AppConfigData.getProfileUrl).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Get user profile failed:', error);
        return throwError(error);
      })
    );
  }

  updateUserProfile(user: any): Observable<any> {
    const formData = new FormData();
    formData.append('FirstName', user.firstName);
    formData.append('LastName', user.lastName);
    formData.append('File', user.profileImg);

    return this.http.post<any>(AppConfigData.updateProfileUrl, formData).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Update user profile failed:', error);
        return throwError(error);
      })
    );
  }

  private storeUserInLocalStorage(user: User): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(user));
  }
}
